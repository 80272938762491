<template>
    <div>
        <b-container class="pos-fix">
            <b-row>
                <b-col cols="12">
                    <p class="py-3 text-center font-size-30">ยืนยันเบอร์โทรศัพท์</p>
                    <p class="color-grey text-center">
                        เราได้ส่งรหัสผ่านไปยัง เบอร์โทรศัพท์
                        {{ $route.query.tel }} กรุณาใส่รหัส OTP 6 หลักในช่องด้านล่าง
                    </p>
                </b-col>
                <b-col cols="12" class="text-center">
                    <p v-if="form.RefCode" class="color-grey">Ref: {{ form.RefCode }}</p>
                    <div class="my-3">
                        <input 
                            v-model="form.OTP"
                            class="btn-otp"
                            type="number" 
                            inputmode="numeric"
                            autocomplete="one-time-code"
                            pattern="\d{6}"
                            required
                            id="partitioned"
                            @change="handleOnChange"
                            oninput="if( this.value.length > 6 )  this.value = this.value.slice(0,6)"
                        >
                    </div>
                </b-col>
                <b-col class="mt-3 text-center" @click="getSendOTP()">
                    <font-awesome-icon :icon="['fas', 'sync-alt']" />
                    <span class="color-grey"> ขอรหัส OTP ใหม่อีกครั้ง</span>
                </b-col>
            </b-row>
            <div class="text-center py-3">
                <b-button @click="onClickPage()">คลิกเพื่อเปลี่ยนเบอร์โทรศัพท์</b-button>
            </div>
            <div class="register-bottom fixed-bottom bg-white p-2 shadow">
                <b-button
                    class="w-100 btn-register"
                    type="button"
                    :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
                    @click="$emit('submitValidateOTP',form)"
                    :disabled="isLoading || form.OTP.length !== 6"
                    >ยืนยัน
                </b-button>
            </div>
        </b-container>   
    </div>
</template>

<script>
export default {
    props:{
        type:{
            required:true,
            type:Number
        }
    },
    data(){
        return{
            form:{
                Telephone: this.$route.query.tel,
                RefCode: "",
                OTP: ""
            },
            isLoading:true
        }
    },
    created: async function () {
        await this.getSendOTP();
    },
    methods:{
        async getSendOTP(){
            let reqOTP = {
                Telephone: this.form.Telephone
            };
            await this.$axios
            .post(`${process.env.VUE_APP_API}/api/v1/user/SendOTP`, reqOTP)
            .then(async data => {
                this.isLoading = false;
                if(data.result == 1){
                    this.form.RefCode = data.detail.ref_code;
                }else{
                    this.isLoading = false;
                }
            });
        },
        handleOnChange(evt) {
            this.form.OTP = evt.target.value;
        },
        onClickPage(){
            this.$emit('clickChangePhone',this.type);
        }
    }
}
</script>

<style lang="scss" scoped>
.pos-fix{
  position: fixed;
}
.btn-otp{
  border-bottom: 1px solid #000;
  border-top: none;
  border-left: none;
  border-right: none;
  text-align: center;
  border-radius: 0px;
}
</style>